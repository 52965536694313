.feature {
  display: flex;
  align-items: center;
  text-align: left;
  justify-content: space-between; /* Keeps space between image and content */
  margin: 2rem 15%; /* Adds 10% margin to the left and right for breathing room */
  padding: 2rem; /* Adds padding inside the feature */
  background-color: white;
}

.feature-reverse {
  flex-direction: row-reverse;
}

.feature-image {
  background-color: #f0f0f0;
  background-size: cover; /* Ensure the image covers the area */
  background-position: center; /* Center the image within the div */
  width: 300px; /* Increased width for a larger image */
  height: 300px; /* Increased height for a larger image */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 2rem; /* Spacing between image and content */
  filter: grayscale(60%); /* Converts the image to grayscale */
  border-radius: 15px; /* Makes the corners rounded */
}

.feature-reverse .feature-image {
  margin-right: 0;
  margin-left: 2rem; /* Spacing for reversed feature */
}

.feature-content {
  flex-grow: 1; /* Takes up remaining space */
  padding-left: 2rem; /* Adds padding between text and feature image */
}

.feature-title {
  color: black;
  font-size: 2rem; /* Larger font size for the title */
  margin-bottom: 1rem; /* Increased space below the title */
  font-family: "rift", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.feature-description {
  font-size: 1.25rem; /* Slightly larger font size for the description */
  color: black; /* Placeholder text color */
  margin-bottom: 1rem; /* Space below the description if more content is added */
}

@media (max-width: 768px) {
  .feature,
  .feature-reverse {
    flex-direction: column;
    align-items: center; /* This ensures that the children are centered */
    margin: 2rem 5%; /* Smaller margin on smaller screens */
  }

  .feature-image {
    width: 80%; /* Full width on smaller screens */
    height: auto; /* Adjust height accordingly */
    margin-right: 0; /* Remove margin for stacked layout on mobile */
    margin-bottom: 1rem; /* Space below the image on mobile */
  }

  .feature-content {
    text-align: center;
    padding-left: 0; /* Reset padding for mobile */
    margin-right: 0; /* Reset right margin */
  }
}
