/* Container for the entire hero section */
.hero {
  display: flex;
  align-items: center;
  justify-content: center; /* Center content, use padding to adjust */
  min-height: 100vh;
  background-color: rgb(226, 230, 228); /* Fallback color */
  background-size: cover;
  background-position: center;
  background-image: url("../../../public/images/hero.jpg"); /* Default large screen image */
  overflow: hidden;
  padding-left: 35%;
  position: relative;
}

.hero-right {
  max-width: 70%; /* Adjusted for a tighter text block */
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
  padding: 2rem; /* Space around the text */
  color: white; /* Text color */
}

/* Style for the main heading in the content */
.hero-heading {
  font-size: 2.5rem;
  color: white;
  margin-bottom: 0.5rem;
  font-family: "rift", sans-serif;
  font-weight: 600;
}

/* Style for the subtext in the content */
.hero-subtext {
  font-size: 1.25rem;
  color: white;
  margin-bottom: 2rem;
}

/* Button styles */
.hero-cta {
  background-color: #ff6c00; /* Brand's primary color */
  color: #fff;
  padding: 1rem 2rem;
  font-size: 1.25rem;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* Button hover effect */
.hero-cta:hover {
  background-color: #e65c00; /* Darker shade for hover effect */
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
  .hero::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../../../public/images/hero-mobile.jpg");
    background-size: cover;
    background-position: center;
    filter: blur(3px);
    z-index: -1;
  }

  .hero {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: none;
    padding-left: 0;
  }

  .hero-right {
    width: 100%;
    max-width: 100%; /* Full width */
    display: flex;
    justify-content: center; /* Horizontal centering */
    align-items: center; /* Vertical centering */
    text-align: center; /* Center text */
    padding: 1rem; /* Adjust padding */
    box-sizing: border-box;
  }

  .hero-heading,
  .hero-subtext,
  .hero-cta {
    position: relative;
    z-index: 1;
    width: auto;
    margin-left: auto;
    margin-right: auto;
  }
}
