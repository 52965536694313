.section-title {
  font-size: 3rem; /* Large size for section title */
  text-align: center;
  margin-top: 2rem; /* Space above and below the title */
  /* font-weight: bold; */
  color: black;
  font-family: "rift", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.products-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 16px;
  padding: 16px;
}

.product-card {
  flex-basis: calc(33.333% - 32px); /* Three items per row, minus the gap */
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  padding: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Responsive styles */
@media (max-width: 768px) {
  .product-card {
    flex-basis: 100%; /* One item per row on the smallest screens */
  }
}
