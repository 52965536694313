.navbar-buttons {
  display: flex;
  font-size: 1.2rem;
  gap: 1.5rem;
}

.navbar-logo {
  flex: 1;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 150px;
  height: 150px;
}

.navbar-right {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.cart-icon {
  font-size: 2rem;
  cursor: pointer;
}

.burger-button {
  font-size: 1.5rem;
  cursor: pointer;
  display: none;
}

.nav-bar-ontop {
  background-color: transparent;
  color: white;
}

@media (max-width: 768px) {
  .navbar-buttons {
    display: none;
  }

  .burger-button {
    display: block;
  }
}
